import qs from "query-string";
import axios from "axios";
import reactImageSize from "react-image-size";

import urls from "../constants/http-urls";

const splitQueryOnSegments = () => {
  const query = window.location.search.substring(1);
  return query.split("&");
};

export const collectUrlParams = () => {
  const segments = splitQueryOnSegments();
  const query = segments.reduce((acc, s) => {
    const parts = s.split("=");
    return { ...acc, [parts[0]]: parts[1] };
  }, {});

  return { ...query };
};

export const validateResponse = (response) => {
  const hasResponse = response && response.data;

  if (!hasResponse) return false;

  const { steps, template } = response.data;

  return steps && template && template.config;
};

export const parseQueryString = () => {
  return qs.parse(window.location.search);
};

export const saveClickAnalytics = ({
  analyticsId,
  experienceId,
  buttonName,
  url,
}) => () => {
  if (!analyticsId) return;

  axios
    .post(`${urls.clickAnalytics.post}`, {
      analyticsId,
      experienceId,
      menuExperienceUrl: url,
      menuExperienceButtonName: buttonName,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .then((response) => response)
    // todo: add better error handling
    .catch((error) => {
      console.error(error);
    });
};

const getBannerImageSize = async (url) => {
  if (url) {
    const { width, height } = await reactImageSize(url);
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    return {
      height,
      width,
      screenWidth,
      screenHeight,
    };
  }

  return { height: null, width: null, screenWidth: null, screenHeight: null };
};

export const calculateWrapperGridRows = async (config) => {
  if (config) {
    const banner = await getBannerImageSize(config.banner.image.src);

    let grid = "";

    if (!config.banner.disabled) {
      grid = `${grid} ${(banner.height * banner.screenWidth) / banner.width}px`;
    }

    if (!config.header.disabled) {
      grid = `${grid} ${config.header.height}`;
    }

    if (!config.body.disabled) {
      grid = `${grid} 1fr`;
    }

    if (!config.footer.disabled) {
      grid = `${grid} ${config.footer.height}`;
    }

    return {
      grid,
      bannerHeight: banner.height,
      screenWidth: banner.screenWidth,
    };
  }
};

const hasQueryParams = (url) => /[?&]/.test(url);

export const buildRedirectUrlWithParams = (url, params) => {
  const query = new URLSearchParams(params).toString();
  return hasQueryParams(url) ? `${url}&${query}` : `${url}?${query}`;
};
